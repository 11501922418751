import React from 'react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { OrderSources, OrderStatuses } from '@bottomless/common/constants';
import { DateFormat } from '@bottomless/common/components';
import { Badge } from 'reactstrap';
import { ProductName } from '@bottomless/common/src/components/ProductV2/components';
import { VariantAttributes } from '@bottomless/common/src/components/VariantAttributes/VariantAttributes';

export const Order = ({ order, user }) => {
  const isOrderOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);
  const { product, variant } = useMemo(
    () => order.subproduct_id || (isOrderOneOff && order.product_id) || user?.product || {},
    [order, isOrderOneOff, user]
  );
  const lineItems = useMemo(
    () => (order.subscriptionContract?.contract_id || isOrderOneOff ? order.lineItems : user?.lineItems) || [],
    [order, isOrderOneOff, user]
  );

  const showScheduledFulfillment = useMemo(() => !order.date_fulfilled && order.override_fulfillment_date, [order]);

  const isOrderWithError = useMemo(
    () =>
      ![OrderStatuses.Cancelled, OrderStatuses.Refunded].includes(order.status) &&
      !order.date_fulfilled &&
      !!order.fulfillmentErrors?.charge?.error,
    [order]
  );

  return (
    <>
      {isOrderOneOff && (
        <Badge color="primary" className="badge-one-off">
          One-Off
        </Badge>
      )}
      {isOrderWithError && (
        <Badge color="danger" className="badge-issue">
          Payment Failure
        </Badge>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <div className="order-product-name">
          {order.shopify_name && <div>{order.shopify_name}</div>}
          {product?.name && !product.personalized_rotation ? (
            <div>
              <span className="font-weight-bold">{product?.name}</span>
              {lineItems.length > 0 && <span> + {lineItems.length} more</span>}
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <ProductName user={user} />
              {lineItems.length > 0 && <span className="ml-1"> + {lineItems.length} more</span>}
            </div>
          )}
          <div className="text-secondary">
            <VariantAttributes productVariant={{ product, variant }} grind={order.grind || user?.grind} />
          </div>
        </div>
        <div className="min-w-fit">
          {showScheduledFulfillment && (
            <>
              <div className="text-uppercase text-secondary small">Processing on</div>
              <div className="font-weight-bold text-right">
                <DateFormat
                  date={order.override_fulfillment_date}
                  correctTimezone={!!order._id && order._id !== 'pending'}
                />
              </div>
            </>
          )}
          {order.date_arrived && (
            <div className="font-weight-bold">
              <DateFormat date={order.date_arrived} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Order.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    subproduct_id: PropTypes.object.isRequired,
    product_id: PropTypes.object.isRequired,
    date_arrived: PropTypes.string,
    grind: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    lineItems: PropTypes.array,
    subscriptionContract: PropTypes.shape({
      contract_id: PropTypes.string,
    }),
    tracking_number: PropTypes.string,
    date_fulfilled: PropTypes.string,
    override_fulfillment_date: PropTypes.string,
    fulfillmentErrors: PropTypes.shape({
      charge: PropTypes.shape({
        error: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
    shopify_name: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    product: PropTypes.object,
    grind: PropTypes.object,
    vendor_id: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    lineItems: PropTypes.array,
    dumb_period: PropTypes.number,
  }),
};
