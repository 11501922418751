import React from 'react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { STEPS } from './steps.constants';
import { Button } from 'reactstrap';
import { SwitchToSubscriptionByUsage } from '../../../../components/SwitchToSubscriptionByUsage';

export const StepSwitchToByUsage = ({ setNextStep }) => {
  const onSwitchSuccess = useCallback(() => setNextStep(STEPS.SubscriptionUpdatedSBU), [setNextStep]);
  const onClick = useCallback(() => setNextStep(STEPS.PauseReordering), [setNextStep]);

  return (
    <>
      <div className="mb-4">No new scheduled orders will be processed. You can still make one-off orders.</div>
      <div className="mt-4 form-sbu">
        <div className="mw-300">
          <Button outline={true} size="default" color="primary" block onClick={onClick}>
            Confirm Cancel
          </Button>
        </div>
      </div>
      <div className="my-4 d-flex align-items-center">
        <hr className="my-0 w-100" />
        <span className="mx-4 text-nowrap">or</span>
        <hr className="my-0 w-100" />
      </div>
      <SwitchToSubscriptionByUsage
        onSuccess={onSwitchSuccess}
        place="settings-cancel-subscription"
        title="Switch to Subscribe By Usage instead?"
      />
    </>
  );
};

StepSwitchToByUsage.headingText = 'Cancel Subscription';

StepSwitchToByUsage.propTypes = {
  me: PropTypes.object.isRequired,
  setNextStep: PropTypes.func.isRequired,
  setDumbPeriod: PropTypes.func.isRequired,
  closeAccount: PropTypes.func.isRequired,
};
