import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  Checkbox,
  DateFormat,
  Form,
  SubmitButton,
  UserProduct,
  DataHandler,
  DataHandlerContent,
} from '@bottomless/common/components';
import { STEPS } from './steps.constants';
import { DumbType, SubscriptionType } from '@bottomless/common/constants';
import { DAYS_IN_WEEK, useSubscriptionFrequency } from '../../hooks/use-subscription-frequency.hook';
import { READABLE_FORMAT } from '../../utils/dates';
import { utcDate } from '@bottomless/common/utils';

export const StepResumeSubscription = ({
  setNextStep,
  pauseAccount,
  me,
  getNextOrderDate,
  isProductFetching,
  makeBottomlessShopify,
  product,
}) => {
  const [dumbPeriod, setDumbPeriod] = useState(me.dumb_period);
  const onSuccess = useCallback(() => setNextStep(STEPS.Done), [setNextStep]);
  const isDynamic = useMemo(() => me.subscriptionType === SubscriptionType.Dynamic, [me]);
  const isFixed = useMemo(() => me.subscriptionType === SubscriptionType.Fixed, [me]);
  const isPrepaid = useMemo(() => me.subscriptionType === SubscriptionType.Prepaid, [me]);
  const isDayDumbType = useMemo(() => !me.dumb_type || me.dumb_type === DumbType.Days, [me]);
  const showFrequencyOption = useMemo(() => !!me.dumb_period && isFixed && isDayDumbType, [isDayDumbType, isFixed, me]);

  const { options: productOptions } = useSubscriptionFrequency();
  const options = useMemo(() => {
    if (!me.dumb_period || productOptions.includes(me.dumb_period / DAYS_IN_WEEK)) {
      return productOptions.map(option => ({
        value: option * DAYS_IN_WEEK,
        label: `${option} week${option > 1 ? 's' : ''}`,
      }));
    }
    const userFrequencyOption =
      me.dumb_period % DAYS_IN_WEEK === 0
        ? {
            value: me.dumb_period,
            label: `${me.dumb_period / DAYS_IN_WEEK} week${me.dumb_period / DAYS_IN_WEEK > 1 ? 's' : ''}`,
          }
        : { value: me.dumb_period, label: `${me.dumb_period} day${me.dumb_period > 1 ? 's' : ''}` };
    return [
      {
        value: productOptions[0] * DAYS_IN_WEEK,
        label: `${productOptions[0]} week${productOptions[0] > 1 ? 's' : ''}`,
      },
      {
        value: productOptions[1] * DAYS_IN_WEEK,
        label: `${productOptions[1]} week${productOptions[1] > 1 ? 's' : ''}`,
      },
      userFrequencyOption,
    ];
  }, [me, productOptions]);

  const onClick = useCallback(({ value, setFieldValue }) => {
    const fieldName = 'dumb_period';
    setFieldValue(fieldName, value);
    setDumbPeriod(value);
  }, []);

  const onSubmit = useCallback(
    async data => {
      if (product) {
        await makeBottomlessShopify(product.shopifyProduct.product, { variant: product.shopifyProduct.variant });
      }

      return pauseAccount(data);
    },
    [pauseAccount, makeBottomlessShopify, product]
  );

  const user = useMemo(() => {
    if (!product) {
      return me;
    }

    return { ...me, product: { product: product.product, variant: product.variant }, grind: product.grind };
  }, [me, product]);

  return (
    <div className="step-resume-subscription">
      {isDynamic ? (
        <div className="mb-3">
          <div>Your next order will be triggered by the scale based on your usage.</div>
        </div>
      ) : (
        <div className="mb-3">
          <div>A new order will be processed on</div>
          <div className="font-weight-bold mt-1">
            <DateFormat date={utcDate(getNextOrderDate(dumbPeriod, false, 1, me, true))} format={READABLE_FORMAT} />
          </div>
        </div>
      )}
      <div className="mb-5">
        You can make changes to your frequency, product, and billing details at any time before the order is processed.
      </div>

      <h4 className="mb-3">Product</h4>
      <div className="d-flex justify-content-between align-items-center text-left mb-5">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center justify-content-between">
            <DataHandler data={isProductFetching ? null : user} isLoading={isProductFetching} />
            <DataHandlerContent data={isProductFetching ? null : user} isLoading={isProductFetching}>
              <UserProduct user={user} avatarProps={{ width: 65, fit: 'clip' }} withPrice={!isPrepaid} />
            </DataHandlerContent>
            <div className="d-flex line-item-end-wrapper mr-2 min-w-fit">
              <div>x{me.quantity || 1}</div>
            </div>
          </div>
          {me.lineItems.map((lineItem, key) => (
            <div key={key} className="d-flex align-items-center justify-content-between mt-3">
              <UserProduct
                user={{ ...me, ...lineItem, product: { product: lineItem.product, variant: lineItem.variant } }}
                avatarProps={{ width: 65, fit: 'clip' }}
                withPrice={!isPrepaid}
              />
              <div className="d-flex line-item-end-wrapper mr-2">
                <div>x{lineItem.quantity || 1}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Form
        initialValues={{
          paused: false,
          sendNotification: true,
          needsScale: false,
          ...(showFrequencyOption ? { dumb_period: me.dumb_period } : {}),
        }}
        onSubmit={onSubmit}
        onSuccess={onSuccess}
        className="form-fixed-period"
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <>
            {showFrequencyOption && (
              <div>
                <h4 className="mb-2">Frequency</h4>
                <div className="d-flex justify-content-between align-items-center text-left mb-5 flex-gap-1">
                  {options.map(option => (
                    <Button
                      key={option.value}
                      block
                      color={values.dumb_period === option.value ? 'dark' : 'primary'}
                      outline={values.dumb_period !== option.value}
                      onClick={() => onClick({ value: option.value, setFieldValue })}
                      className="mt-0"
                    >
                      {option.label}
                    </Button>
                  ))}
                </div>
              </div>
            )}
            <SubmitButton className="mw-300" block size="default" isSubmitting={isSubmitting}>
              Confirm Restart
            </SubmitButton>
            {isDynamic && (
              <div className="mt-2">
                <Checkbox name="needsScale" label="I need a new scale" />
              </div>
            )}
          </>
        )}
      </Form>
      <div className="mt-5">
        {isDynamic && <span className="font-weight-bold">*Automatic re-ordering will be turned on. </span>}
      </div>
    </div>
  );
};

StepResumeSubscription.headingText = 'Restart Subscription';

StepResumeSubscription.propTypes = {
  me: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    lineItems: PropTypes.array,
    dumb_period: PropTypes.number,
    dumb_type: PropTypes.string,
    product: PropTypes.object.isRequired,
    shopifySubscriptionContractId: PropTypes.string,
  }),
  setNextStep: PropTypes.func.isRequired,
  pauseAccount: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  isProductFetching: PropTypes.bool,
  product: PropTypes.shape({
    product: PropTypes.object,
    variant: PropTypes.string,
    grind: PropTypes.object,
    shopifyProduct: PropTypes.shape({
      product: PropTypes.string.isRequired,
      variant: PropTypes.string.isRequired,
    }).isRequired,
  }),
  makeBottomlessShopify: PropTypes.func,
};
