import { Box, DataHandler, DataHandlerContent } from '@bottomless/common/components';
import PropTypes from 'prop-types';
import { OrderSources, OrderStatuses } from '@bottomless/common/constants';
import { useQueryString } from '@bottomless/common/hooks';
import { useToast } from '@bottomless/common/hooks';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Heading } from '../../components/Heading';
import { PanelSection } from '../../components/PanelSection';
import { PanelLayout } from '../../layouts/PanelLayout';
import {
  Actions,
  Address,
  AmountsPaid,
  ChargeFailure,
  DataGraph,
  OrderTiming,
  Product,
  SubproductSelection,
  Tracking,
} from './components';
import {
  useCleanData,
  useInTransitionIndex,
  useLastSubscriptionOrder,
  useMe,
  useOrder,
  useQueue,
  useStats,
  useUpcomingOrder,
} from './hooks';
import './OrderPage.scss';
import { PanelLayoutGuest } from '../../layouts/PanelLayoutGuest';
import { setUserLoggedInAction } from '../../store/user';
import { useLastOrderDate } from '../../hooks/use-last-order-date';
import { StripeLoader } from '../../components/StripeLoader';

export const OrderPageComponent = ({ setUserLoggedIn, isLoggedIn }) => {
  const { id, productId, variantId } = useParams();
  const {
    params: { back },
  } = useQueryString();
  const me = useMe();

  const lastOrder = useLastSubscriptionOrder();
  const upcomingOrders = useUpcomingOrder();
  const inTransitionIndex = useInTransitionIndex(id);

  const { getNextOrderDate, getNextOrderDateFromNow } = useLastOrderDate({
    lastOrder,
    upcomingOrders,
    user: me?.data,
  });

  const order = useOrder(id, productId, variantId, me?.data, getNextOrderDate, upcomingOrders.data);
  const queue = useQueue();
  const cleanData = useCleanData(order.data?.user?._id);
  const stats = useStats();
  const isSimpleSubscription = useMemo(() => !!order.data?.user?.shopifySubscriptionContractId, [order.data]);

  const isOrderOneOff = useMemo(() => order.data?.order?.source === OrderSources.USER_ONE_OFF, [order.data]);

  const firstQueueElement = useMemo(() => (queue.data?.length > 0 ? queue.data[inTransitionIndex] : null), [
    queue,
    inTransitionIndex,
  ]);

  const orderProduct = useMemo(() => {
    const userOrder = order.data?.order;

    if (userOrder?.subproduct_id?.product) {
      return userOrder.subproduct_id;
    }

    if (isOrderOneOff && userOrder?.product_id) {
      return userOrder.product_id;
    }

    return firstQueueElement || order.data?.user?.product;
  }, [isOrderOneOff, order.data, firstQueueElement]);

  const overrideGrind = useMemo(() => {
    const isNotProcessed = [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated].includes(
      order.data?.order?.status
    );

    return isNotProcessed && !isOrderOneOff ? firstQueueElement?.grind : undefined;
  }, [order, firstQueueElement, isOrderOneOff]);

  const Wrapper = useMemo(() => (me?.data ? PanelLayout : PanelLayoutGuest), [me]);

  const onCancelSuccess = useToast('Order has been cancelled.');

  const onCancel = useCallback(async () => {
    await order.cancel();
    onCancelSuccess();
  }, [order, onCancelSuccess]);

  const lineItems = useMemo(
    () =>
      (order.data?.order?.subscriptionContract?.contract_id || isOrderOneOff
        ? order.data.order.lineItems
        : order.data?.user?.lineItems) || [],
    [order.data, isOrderOneOff]
  );

  const pricingRule = useMemo(() => order.data?.order?.pricing_rule || me?.data?.pricing_rule, [order, me]);

  const pageTitle = useMemo(() => {
    const shopifyName = order.data?.order?.shopify_name;
    return `Order${shopifyName ? ` ${shopifyName}` : ''} Status`;
  }, [order.data?.order?.shopify_name]);

  useEffect(() => {
    if (!isLoggedIn && me?.data) {
      setUserLoggedIn();
    }
  }, [isLoggedIn, setUserLoggedIn, me]);

  return (
    <Wrapper>
      <div className="page page-order">
        <Heading back={me?.data ? back || '/' : null}>{pageTitle}</Heading>
        <DataHandler {...order} />
        <DataHandlerContent {...order}>
          {order.data?.order && orderProduct && (
            <>
              <Row>
                <Col xs="12" md="6">
                  <PanelSection title="Product" className="page-order-section">
                    <Box>
                      <Product
                        order={order.data.order}
                        user={order.data.user}
                        product={orderProduct}
                        isSimpleSubscription={isSimpleSubscription}
                        pricingRule={pricingRule}
                        overrideGrind={overrideGrind}
                        isMainProduct
                        isQueueProduct={!!firstQueueElement}
                      />
                      {lineItems.map((lineItem, key) => (
                        <Product
                          key={key}
                          order={lineItem}
                          user={order.data.user}
                          product={lineItem}
                          isSimpleSubscription={isSimpleSubscription}
                          pricingRule={pricingRule}
                        />
                      ))}
                    </Box>
                    <Actions
                      me={me?.data}
                      order={order.data.order}
                      product={orderProduct}
                      onCancel={onCancel}
                      onDelay={order.overrideDate}
                      queue={queue.data}
                      reportProblem={order.reportProblem}
                      skipOrder={order.skipOrder}
                      getNextOrderDate={getNextOrderDate}
                      getNextOrderDateFromNow={getNextOrderDateFromNow}
                      vendor={upcomingOrders.data?.vendor}
                    />
                  </PanelSection>
                  <SubproductSelection
                    me={me?.data}
                    order={order.data.order}
                    selections={order.data.selections}
                    setProduct={order.setProduct}
                  />
                  <AmountsPaid
                    order={order.data.order}
                    user={order.data.user}
                    lineItems={lineItems}
                    product={orderProduct}
                    pricingRule={pricingRule}
                    discountCode={order.data.discountCode}
                    me={me?.data}
                    isQueueProduct={!!firstQueueElement}
                  />
                  <Address order={order.data.order} user={order.data.user} verifyAddress={order.verifyAddress} />
                  <DataGraph order={order.data.order} cleanData={cleanData.data} stats={stats?.data} />
                </Col>
                <Col xs="12" md="6">
                  <StripeLoader>
                    <ChargeFailure
                      me={me.data}
                      order={order.data.order}
                      verifyStripe={me.verifyStripe}
                      sendPaymentUpdateEmail={me.sendPaymentUpdateEmail}
                    />
                  </StripeLoader>

                  <Tracking
                    order={order.data.order}
                    category={(order.data.order.subproduct_id || order.data.order.product_id)?.product?.category}
                    expectedDate={order.expectedDate}
                    withHeading={false}
                  />
                  <OrderTiming me={me?.data} order={order.data.order} onOrderFeedback={order.sendFeedback} />
                </Col>
              </Row>
            </>
          )}
        </DataHandlerContent>
      </div>
    </Wrapper>
  );
};

OrderPageComponent.propTypes = {
  isLoggedIn: PropTypes.bool,
  setUserLoggedIn: PropTypes.func.isRequired,
};

export const OrderPage = connect(
  ({ user: { isLoggedIn } }) => ({ isLoggedIn }),
  dispatch => ({
    setUserLoggedIn: () => dispatch(setUserLoggedInAction()),
  })
)(OrderPageComponent);
