import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DumbType, OrderSources, OrderStatuses, SubscriptionType } from '@bottomless/common/constants';
import { SubmitButton } from '@bottomless/common/components';
import { useActionLoading } from '../../../hooks/use-action-loading.hook';
import { PanelSection } from '../../../components/PanelSection';
import { useIsProcessed } from '../../../hooks/use-is-processed.hook';
import { useConfigValue } from '../../../hooks/use-config-value.hook';
import { ReportProblem } from './ReportProblem';
import { DelayOrder } from '../../../components/DelayOrder/DelayOrder';
import { SkipOrder } from '../../../components/SkipOrder/SkipOrder';
import { utcDate } from '@bottomless/common/utils';
import { useToggle } from '@bottomless/common/hooks';
import { ErrorMessage } from './ErrorMessage';

const CANCELLABLE_SOURCES = [
  OrderSources.USER,
  OrderSources.DUMB_SUBSCRIPTION,
  OrderSources.USER_ONE_OFF,
  OrderSources.OPERATIONS,
];

const CANCELLABLE_STATUSES = [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated];

const DELAYABLE_STATUSES = [OrderStatuses.Initiated, OrderStatuses.SubproductGenerated, OrderStatuses.Paid];

export const Actions = ({
  me,
  order,
  onCancel,
  product,
  queue,
  onDelay,
  reportProblem,
  skipOrder,
  getNextOrderDate,
  getNextOrderDateFromNow,
  vendor,
}) => {
  const [isSkipOpen, skipToggle] = useToggle();
  const useProductAccountWidget = useConfigValue('useProductAccountWidget');

  const isProcessed = useIsProcessed({ order });
  const queueProduct = useMemo(() => queue[0], [queue]);

  const isOneOff = useMemo(() => order.source === OrderSources.USER_ONE_OFF, [order]);

  const isCancellable = useMemo(
    () =>
      (order.source === OrderSources.USER_ONE_OFF && CANCELLABLE_STATUSES.includes(order.status)) ||
      (!me?.dumb_period && CANCELLABLE_SOURCES.includes(order.source) && CANCELLABLE_STATUSES.includes(order.status)),
    [order, me]
  );
  const isDelayable = useMemo(
    () =>
      (isOneOff ||
        me?.subscriptionType === SubscriptionType.Dynamic ||
        (me?.subscriptionType === SubscriptionType.Fixed &&
          ![DumbType.FirstThursday, DumbType.FirstWednesday, DumbType.SameDayMonthly].includes(me.dumb_type))) &&
      DELAYABLE_STATUSES.includes(order.status),
    [order, me, isOneOff]
  );
  const isSkippable = useMemo(
    () => !isOneOff && me?.subscriptionType !== SubscriptionType.Dynamic && DELAYABLE_STATUSES.includes(order.status),
    [order, me, isOneOff]
  );
  const isProductChangable = useMemo(
    () => me?.subscriptionType !== SubscriptionType.Prepaid && !isProcessed && !isOneOff,
    [isProcessed, isOneOff, me]
  );
  const isReportable = useMemo(() => order.date_arrived && order.status !== OrderStatuses.Cancelled, [order]);

  const handleCancel = useActionLoading(onCancel, true);

  const LinkComponent = useCallback(
    props =>
      useProductAccountWidget ? (
        <a {...props} href="/collections/subscription-by-usage" />
      ) : (
        <Link {...props} to="/?updateProduct=true" />
      ),
    [useProductAccountWidget]
  );

  const hasSomeActions = isProductChangable || isCancellable || isDelayable || isReportable || isSkippable;

  if (!me || !hasSomeActions) {
    return null;
  }

  return (
    <PanelSection>
      {isProductChangable && queueProduct && !isOneOff && (
        <div className="small text-secondary mt-2">This product is selected from your queue.</div>
      )}
      <div className="d-flex flex-wrap mt-4 mb-n3">
        {isReportable && <ReportProblem order={order} reportProblem={reportProblem} />}
        {isProductChangable && !queueProduct && (
          <LinkComponent className="btn btn-primary mr-3 mb-3">Change your product</LinkComponent>
        )}
        {isProductChangable && queueProduct && (
          <Link className="btn btn-primary mr-3 mb-3" to="/?openQueue=true">
            Edit queue
          </Link>
        )}
        {isCancellable && (
          <SubmitButton
            type="button"
            size="default"
            onClick={handleCancel.execute}
            isSubmitting={handleCancel.isLoading}
            loadingText="Cancelling"
            className="mr-3 mb-3"
          >
            Cancel
          </SubmitButton>
        )}
        {isDelayable && !isSkippable && (
          <DelayOrder
            order={order}
            product={product}
            overrideDate={onDelay}
            delayOrderText="Reschedule order"
            delayOrderButtonText="Choose date"
            className={classNames('d-flex align-items-center mb-3', { 'mr-3': isSkippable })}
          />
        )}
        {isSkippable && (
          <SkipOrder
            me={me}
            skipOrder={skipOrder}
            className="d-flex align-items-center mb-3"
            isOpen={isSkipOpen}
            toggle={skipToggle}
            fulfillmentDate={utcDate(getNextOrderDate(me.dumb_period, true, 1, me))}
            nextFulfillmentDate={utcDate(
              order._id === 'pending' && (me.dumb_type === DumbType.Days || !me.dumb_type)
                ? getNextOrderDateFromNow(me.dumb_period)
                : getNextOrderDate(me.dumb_period, false, 2, me)
            )}
            vendor={vendor}
          >
            Skip Order
          </SkipOrder>
        )}
      </div>
      <ErrorMessage error={handleCancel.error} />
    </PanelSection>
  );
};

Actions.propTypes = {
  me: PropTypes.object,
  product: PropTypes.object.isRequired,
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    date_arrived: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
  queue: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onDelay: PropTypes.func.isRequired,
  onDelaySuccess: PropTypes.func.isRequired,
  reportProblem: PropTypes.func.isRequired,
  skipOrder: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  getNextOrderDateFromNow: PropTypes.func.isRequired,
  vendor: PropTypes.object,
};
