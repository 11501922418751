import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { StopCircle } from 'react-feather';
import { ResumeSubscription } from '../../../components/ResumeSubscription';
import './ClosedAccount.scss';

export const ClosedAccount = ({ me, pauseAccount, isClosed, getNextOrderDate, makeBottomlessShopify }) => (
  <div className={className({ 'mb-5': isClosed, 'closed-account': true })}>
    {isClosed && (
      <>
        <div>
          <span className="mr-2">
            <StopCircle className="stop-circle" width="32" height="32" fill="#dc3444" strokeWidth="1.25px" />
          </span>
          <span>
            Your subscription was <span className="font-weight-bold">cancelled</span>.
          </span>
        </div>
        <div className="mb-4">No scheduled order will be processed.</div>
      </>
    )}
    <ResumeSubscription
      me={me}
      pauseAccount={pauseAccount}
      getNextOrderDate={getNextOrderDate}
      makeBottomlessShopify={makeBottomlessShopify}
    />
    {isClosed && <hr className="mt-5" />}
  </div>
);

ClosedAccount.propTypes = {
  me: PropTypes.shape({
    hibernate: PropTypes.bool,
  }).isRequired,
  isClosed: PropTypes.bool,
  pauseAccount: PropTypes.func.isRequired,
  getNextOrderDate: PropTypes.func.isRequired,
  makeBottomlessShopify: PropTypes.func.isRequired,
};
