import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-feather';
import { Box, UserProduct, Queue } from '@bottomless/common/components';
import { useToggle } from '@bottomless/common/hooks';
import { SubscriptionType } from '@bottomless/common/constants';
import { useModal } from '../../../../hooks/use-modal.hook';
import { ChangeProduct } from '../ChangeProduct';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CustomList } from '../CustomList';
import { useStore } from 'react-redux';
import { useConfigValue } from '../../../../hooks/use-config-value.hook';
import { useIsSimpleSubscription } from '../../../../hooks/use-is-simple-subscribtion.hook';
import { ChangeSimpleSubscription } from '../ChangeSimpleSubscription/ChangeSimpleSubscription';
import { AddProduct } from '../AddProduct';
import { EditLineModal } from '../EditLineModal';

import './ProductPreference.scss';
import { useSubscriptionFrequency } from '../../../../hooks/use-subscription-frequency.hook';

export const ProductPreference = ({
  me,
  queue,
  productsState,
  attributes,
  makeBottomless,
  moveCustomListItem,
  updateCustomList,
  deleteCustomListItem,
  openQueue,
  onModalClose,
  updateLineItemsAmounts,
  optimisticLineItemsAmountsUpdate,
  addLineItem,
  updateLineItems,
}) => {
  const isSimpleSubscription = useIsSimpleSubscription();
  const isPrepaidSubscription = useMemo(() => me.subscriptionType === SubscriptionType.Prepaid, [me]);
  const store = useStore();
  const modalProps = useModal();
  const [isOpen, rawToggle] = useToggle(!!openQueue);
  const withQueue = useMemo(() => Array.isArray(queue), [queue]);
  const isQueueEmpty = useMemo(() => queue?.length === 0, [queue]);
  const [isEditLineModalOpen, toggleEditLineModal] = useToggle();
  const [lineSelected, setLineSelected] = useState();

  const useQueueAccountWidget = useConfigValue('useQueueAccountWidget');
  const useProductAccountWidget = useConfigValue('useProductAccountWidget');

  const { availableOtherOptions } = useSubscriptionFrequency();

  const hideUpdateButton = useMemo(() => availableOtherOptions.length <= 1, [availableOtherOptions]);

  const handleCustomListUpdate = useCallback(
    data => {
      const products = store
        .getState()
        .product.wishlist.map(({ product, variant, grind }) => ({ product: product._id, variant, grind: grind?._id }));

      return updateCustomList({
        products: products,
        timestamp: Date.now(),
        indexChange: data,
      });
    },
    [store, updateCustomList]
  );

  const onDeleteCustomListItem = useCallback(
    (...data) => {
      deleteCustomListItem(...data);
      return handleCustomListUpdate();
    },
    [deleteCustomListItem, handleCustomListUpdate]
  );

  const onMoveCustomListItem = useCallback(
    (...data) => {
      moveCustomListItem(...data);
      return handleCustomListUpdate(data);
    },
    [moveCustomListItem, handleCustomListUpdate]
  );

  const toggle = useCallback(() => {
    onModalClose();
    rawToggle();
  }, [rawToggle, onModalClose]);

  const isLineItemEditable = useMemo(() => isSimpleSubscription && !useProductAccountWidget, [
    isSimpleSubscription,
    useProductAccountWidget,
  ]);

  const handleEditClick = useCallback(
    index => {
      setLineSelected(index);
      toggleEditLineModal();
    },
    [toggleEditLineModal]
  );

  return (
    <>
      <Box>
        {(useQueueAccountWidget || !isQueueEmpty) && withQueue && (
          <>
            <div>
              <Queue queue={queue} onEditClick={toggle} isQueueEmpty={isQueueEmpty} />
              <hr className="my-4" />
            </div>
            <div className="mb-3">
              <div className="font-weight-bold">Default</div>
              <div className="small text-secondary">After queue is finished</div>
            </div>
          </>
        )}
        <div className="d-flex justify-content-between align-items-center user-product">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center justify-content-between">
              <UserProduct user={me} avatarProps={{ width: 65, fit: 'clip' }} withPrice={!isPrepaidSubscription} />
              {(isSimpleSubscription || me.quantity > 1) && (
                <div className="d-flex line-item-end-wrapper">
                  <div>x{me.quantity || 1}</div>{' '}
                  {isLineItemEditable && (
                    <div className="d-flex align-items-center flex-wrap">
                      <Edit
                        className="cursor-pointer"
                        width="20"
                        height="20"
                        strokeWidth="1.5"
                        onClick={() => handleEditClick(0)}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {me.lineItems.map((lineItem, key) => (
              <div key={key} className="d-flex align-items-center justify-content-between">
                <UserProduct
                  user={{ ...me, ...lineItem, product: { product: lineItem.product, variant: lineItem.variant } }}
                  avatarProps={{ width: 65, fit: 'clip' }}
                  withPrice={!isPrepaidSubscription}
                />
                {isSimpleSubscription && (
                  <div className="d-flex line-item-end-wrapper">
                    <div>x{lineItem.quantity || 1}</div>{' '}
                    {isLineItemEditable && (
                      <div>
                        <Edit
                          className="cursor-pointer"
                          width="20"
                          height="20"
                          strokeWidth="1.5"
                          onClick={() => handleEditClick(key + 1)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          {!isSimpleSubscription && !isPrepaidSubscription && (
            <div className="ml-2">
              <ChangeProduct
                user={me}
                productsState={productsState}
                attributes={attributes}
                makeBottomless={makeBottomless}
              />
            </div>
          )}
        </div>
        {isSimpleSubscription && !isPrepaidSubscription && !hideUpdateButton && (
          <div className="mt-4">
            {useProductAccountWidget && (
              <ChangeSimpleSubscription
                user={me}
                updateLineItemsAmounts={updateLineItemsAmounts}
                optimisticLineItemsAmountsUpdate={optimisticLineItemsAmountsUpdate}
              />
            )}
            {!useProductAccountWidget && (
              <AddProduct
                user={me}
                productsState={productsState}
                attributes={attributes}
                addLineItem={addLineItem}
                block
                outline
                size="default"
              />
            )}
          </div>
        )}
      </Box>
      <Modal isOpen={isOpen} size="lg" toggle={toggle} {...modalProps} className="modal-queue">
        <ModalHeader toggle={toggle}>Edit your queue</ModalHeader>
        <ModalBody>
          {me && (
            <>
              <CustomList
                withAddNew={false}
                wishlist={queue}
                deleteCustomListItem={onDeleteCustomListItem}
                moveCustomListItem={onMoveCustomListItem}
                productPricingRule={me.pricing_rule}
                me={me}
              />
              {useQueueAccountWidget && (
                <div className="text-center mt-4">
                  <a className="btn btn-sm btn-outline-primary" href="/collections/subscription-by-usage">
                    + Add more products
                  </a>
                </div>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
      {isLineItemEditable && (
        <EditLineModal
          user={me}
          productsState={productsState}
          attributes={attributes}
          lineSelected={lineSelected}
          updateLineItems={updateLineItems}
          isOpen={isEditLineModalOpen}
          toggle={toggleEditLineModal}
        />
      )}
    </>
  );
};

ProductPreference.propTypes = {
  me: PropTypes.object.isRequired,
  queue: PropTypes.array.isRequired,
  productsState: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
  makeBottomless: PropTypes.func.isRequired,
  moveCustomListItem: PropTypes.func.isRequired,
  updateCustomList: PropTypes.func.isRequired,
  deleteCustomListItem: PropTypes.func.isRequired,
  openQueue: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  updateLineItemsAmounts: PropTypes.func.isRequired,
  optimisticLineItemsAmountsUpdate: PropTypes.func.isRequired,
  addLineItem: PropTypes.func.isRequired,
  updateLineItems: PropTypes.func.isRequired,
};
